/* eslint-disable camelcase */
import React from 'react'
import './CartItemList.css'
import Bimg from  '../../images/static-imgs/rasberry1.webp'
import CartQuantity from '../CartQuantity/CartQuantitty';
import CircularProgress from '@mui/material/CircularProgress';
import MediaQuery from 'react-responsive'
import Mimg from  '../../images/static-imgs/mango1.webp'
import Pimg from  '../../images/static-imgs/pineapple1.webp'
import useStore from '../../context/StoreContext'
import {Link} from 'gatsby'
import {useState, useEffect} from 'react'
//import Oimg from '../../images/static-imgs/orange1.webp'
//import Simg from '../../images/static-imgs/strawberry1.webp'
//import Wimg from '../../images/static-imgs/watermelon1.webp'

const images = [Pimg, Bimg,Mimg]

export default function({items, removeFromCart,loading, updated}) {
  const [price, setPrice] = useState()
  const [soldOutMessage, setSoldOutMessage] = useState('')
  const [cartUpdated, setCartUpdated] = useState(0)
  const [purchaseType, setPurchaseType] = useState('onetime')
  const [completed, setCompleted] = useState({})

  const { removeLineItem } = useStore()

  useEffect(()=>{
    checkForCompletedCheckout()
  },[items])

  async function checkForCompletedCheckout(){
    let completedCheckout = await JSON.parse(localStorage.getItem('completedCheckout')) 
    
    
    if(completedCheckout !== null){
      completedCheckout['completed'] = true
      setCompleted(completedCheckout)
    }
  }


  if (loading) return (
    <div className = 'cart-item'>
      <div style = {{width:'100%', height:'100%', display:'flex',justifyContent:'center', alignItems:'center'}}>
        <CircularProgress />
      </div>
    </div>
  )

  if (completed.completed && items.length === 0)
    return (
      <div className = 'empty-cart'>
        <h1>Order Placed!</h1>
        <p>You're one step closer to a hangover-free life.</p>
        <a href = {completed.orderStatusUrl} style = {{textDecoration:'none'}}>
          <div className = 'continue-shopping-button'>
            View Your Order
          </div>
        </a>
      </div>
    )

  if (items.length === 0)
    return (
      <div className = 'empty-cart'>
        <div className = 'empty-cart-header'>
          Your cart is empty
        </div>
        <Link to = '/electrolyte-powder-drink-that-prevents-hangovers' style = {{textDecoration:'none', color:'black'}}>
          <div className = 'continue-shopping-button'>
            Continue shopping
          </div>
        </Link>
      </div>
    )

  const mapCartItemsToItems = items => {
    const cartItems = items.map(({product, quantity}) => {
     

      return (
        <>
        <MediaQuery maxWidth={600} >
        <div className="cart-item" key={product.title}>
          
          <Link to = '/electrolyte-powder-drink-that-prevents-hangovers' className="cart-item-image-div" style = {{textDecoration:'none', color:'black'}}>
            <img className="cart-item-image" src={product.images[0].src} />
          </Link>
          <Link to = '/electrolyte-powder-drink-that-prevents-hangovers' className = 'cart-item-attributes' style = {{textDecoration:'none', color:'black'}}>
            <div className="cart-item-info-name">
              <div className =  'ciif1'>{product.title}</div>
              <div className = 'ciif2'>${product.priceRangeV2.maxVariantPrice.amount}</div>
            </div>

            

            
          </Link>
          <div className="cart-item-info-quantity-div">
              <CartQuantity quantity = {quantity} product = {product}/>

             
            </div>
        </div>
        </MediaQuery>

        <MediaQuery minWidth={601} >
          <div className="cart-item" key={product.title}>
          <Link to = '/electrolyte-powder-drink-that-prevents-hangovers' className="cart-item-image-div" style = {{textDecoration:'none', color:'black'}}>
            <img className="cart-item-image" src={product.images[0].src} />
          </Link>

          <Link to = '/electrolyte-powder-drink-that-prevents-hangovers' className="cart-item-info-name" style = {{textDecoration:'none', color:'black'}}>
            <div className =  'ciif1'>{product.title}</div>
            <div className = 'ciif2'>${product.priceRangeV2.maxVariantPrice.amount}</div>
          </Link>

          <div className="cart-item-info-quantity-div">
            <CartQuantity quantity = {quantity} product = {product}/>
          </div>

          <div className="cart-item-info-price">
            <div className="cart-item-info-price-text">
              {`$${(parseFloat(product.priceRangeV2.maxVariantPrice.amount) * quantity).toFixed(2)}`}
            </div>

            <div
              className="cart-item-info-price-remove"
              onClick={() => removeLineItem(product.title)}
            >
              Delete
            </div>
          </div>
        </div>
        </MediaQuery>
        </>
      )
    })
    return cartItems
  }

  if(items.length > 0)return(
    <>
      <div className = 'cart-item-list-header'>
        <div className = 'cart-item-list-header-text'>Your cart</div>
        <Link to = '/electrolyte-powder-drink-that-prevents-hangovers' style = {{textDecoration:'none', color:'black'}} className = 'bts-link'><div className = 'back-to-shopping'>Continue shopping</div></Link>
      </div>
      <div className = 'cart-items-list'>
        {mapCartItemsToItems(items)}
      </div>


      
    </>

  )
}
