import React from 'react'
import './CartSummary.css'
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {useState, useEffect} from 'react'

export default function ({handleCheckout, checkout, applyDiscount, removeAppliedDiscount, discountLoading}){

  const preorderMode = false

  useEffect(() => {
    if (preorderMode) {
      
      applyDiscount('preorder')
    }
    checkForLSDiscount()
  }, [checkout.id])
  
  const [discountCode, setDiscountCode] = useState('')
  const [discountError, setDiscountError] = useState('')

  function checkForLSDiscount() {
    const urlDiscount = localStorage.getItem('urlDiscount')
    if (urlDiscount) {
      applyDiscount(urlDiscount)
    }
  }

  async function handleApply(){
    let discountAdded = await applyDiscount(discountCode)
    setDiscountError(discountAdded.error)
  }

  function handleRemoveDiscount(){
    removeAppliedDiscount()
  }

  function handleChange(e){
    e.preventDefault()
    setDiscountCode(e.target.value)
   
  }
  
  
  return (

    <div className = 'checkout-bottom-row'>
      
      <div className = 'discount-input'>
        <Stack spacing={0} direction = 'column'>
            <Stack spacing={2} direction = 'row'>
              <Stack spacing={0} direction = 'column'>
                <div className = 'discount-input-field'>
                  <input className = 'discount-input' type = 'text' placeholder = 'Discount Code' onChange={handleChange}/>
                  
                </div>
                <div className = 'discount-error-message'>{discountError}</div>
              </Stack>
              <div className = 'apply-discount-button' onClick = {handleApply} >{discountLoading?<CircularProgress />:'Apply'}</div>
            </Stack>
        </Stack>
      </div>
      
      <div className = 'checkout-row'>

        
          <Stack spacing={0} direction = 'column' divider={<Divider orientation="horizontal" flexItem />}>
          {checkout.discountApplications?.length > 0 && (
            <>
          <div className = 'subtotal'>
            <strong>Sub total:     </strong><span style = {{marginLeft:'10px'}}>${checkout.lineItemsSubtotalPrice.amount}</span>
          </div>
          
            <div className = 'discount' >
             <Chip label= {checkout.discountApplications[0]?.code} variant = "outlined" onDelete={handleRemoveDiscount} /><span style = {{marginLeft:'10px'}}> - ${(parseFloat(checkout.lineItemsSubtotalPrice.amount) - parseFloat(checkout.paymentDue)).toFixed(2)}</span>
            </div>
            </>
          )
          }
          <div className = 'subtotal'>
            <strong>Total:     </strong><span style = {{marginLeft:'10px'}}>${checkout.totalPrice}</span>
          </div>
          </Stack>
  

        {checkout.paymentDue === "0.00"? 
          <div className ='checkout-button-disabled'>
          Check Out
        </div>
        :
        <div className ='checkout-button' onClick={handleCheckout}>
          Check Out
        </div>
        }
      </div>


     
    </div>
  )
}
