/* eslint-disable camelcase */
import React, {useState, useEffect} from 'react'
import './cart.css'
import './index.css'
import CartItemList from '../components/CartItemList'
import CartSummary from '../components/CartSummary'
import Layout from '../components/layout'
import Seo from '../components/SEO'
import SoldOut from '../components/SoldOut'
import useStore from '../context/StoreContext'
import {track} from '../../tracking'

const Cart = ({location}) => {
  const [soldOutActive, setSoldOutActive] = useState(false)
  const { cart, checkout, loading, addDiscount, removeDiscount } = useStore()
  const [discountLoading, setDiscountLoading] = useState(false)
  
  useEffect(()=>{
   
    if(cart.length > 0){
      track('View Cart Page',{
        'Cart Size':calculateCartTotalQuantity(),
        'Cart Value':checkout.paymentDue,
        'Cart Item Ids': getCartItemIds(),
        'Cart Item Names': getCartItemNames(),
        'Discount Code':getDiscountCode(),
        'Discount Amount':getDiscountAmount(),
      })
    }
   
  },[cart])

  function calculateCartTotalQuantity(){
    let total = 0
    cart.forEach((item)=>{

      total += item.quantity
    })

    return total
  }

  function getCartItemIds(){
    let ItemIds = cart.map((item)=>{
      return item.product.id
    })
    return ItemIds
  }

  function getCartItemNames(){
    let ItemNames = cart.map((item=>{
      return item.product.title
    }))
    return ItemNames
  }

  function getDiscountCode(){
    if(checkout.discountApplications){
      return checkout.discountApplications[0]?.code
    }
    return ''
  }

  function getDiscountAmount(){
    if(checkout.lineItemsSubtotalPrice){
      return (parseFloat(checkout.lineItemsSubtotalPrice.amount) - parseFloat(checkout.paymentDue)).toFixed(2)
    }
  }

  async function applyDiscount(code){
    setDiscountLoading(true)
    let discountAdded = await addDiscount(code)
   
    setDiscountLoading(false)
    return discountAdded
  }

  async function removeAppliedDiscount(){
    await removeDiscount()
  }

  function handleCheckout(){
    track('Open Checkout',{
      'Cart Size':calculateCartTotalQuantity(),
      'Cart Value':checkout.paymentDue,
      'Cart Item Ids': getCartItemIds(),
      'Cart Item Names': getCartItemNames(),
      'Discount Code':getDiscountCode(),
      'Discount Amount':getDiscountAmount(),
      'Location': 'Cart Page'
    })
    try{
      typeof window !== 'undefined' &&
      window.gtag('event', 'conversion', {
        'send_to': 'AW-10829673925/5TJqCNGM-qEDEMXr_qso',
      });
      window.gtag('event', 'Open Checkout', {
        'Cart Size':calculateCartTotalQuantity(),
        'Cart Value':checkout.paymentDue,
        'Cart Item Ids': getCartItemIds(),
        'Cart Item Names': getCartItemNames(),
        'Discount Code':getDiscountCode(),
        'Discount Amount':getDiscountAmount(),
        'Location': 'Cart Page'
      })
    }catch(e){
      console.log('error tracing gtag conversion',e)
    }
   

    window.open(checkout.webUrl, "_self")
  }

  return (
    <>
      <SoldOut open={soldOutActive} setSoldOutActive={setSoldOutActive} />
      <Layout location={location} open={soldOutActive} background={false}>
        <Seo title="Cart" description = 'Time to check out innit?'/>
        <div className = 'cart-container'>
          <CartItemList
            items={cart}
            loading={loading}
             
            
          
          />
        
          <CartSummary
            checkout = {checkout}
            handleCheckout={handleCheckout}
            applyDiscount={applyDiscount}
            removeAppliedDiscount={removeAppliedDiscount}
            discountLoading={discountLoading}
          />
          
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
      </Layout>
    </>
  )
}

export default Cart
